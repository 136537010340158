// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Changing the border radius of buttons
//$border-radius: 15px;

// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
$theme-colors: (
    "cool": #4d3fa3
);

// Base colors
$primary: #f6511d;
$secondary: #436296;
$success: #155724;
$info: #00a6ed;
$warning: #ffb400;
$danger: #d6211d;
$light: #e5e5e5;
$dark: #0d2c54;
$dark-66: #0d2c5466;

// Color override
$body-bg: #ccc;
$body-color: #333;
$link-color: $warning;
$breadcrumb-bg: $dark;


$pagination-active-color: #fff;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;
$nav-pills-link-active-color: #fff;
$nav-pills-link-active-bg: $dark-66;


// Bootstrap theme settings
$enable-shadows: false;
$enable-gradients: false;
